<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\documents\\list.9537') }}</v-toolbar-title>
                <v-spacer />
                <v-btn v-if="user.adminAccess && user.adminAccess.documents >= ACCESS.WRITE" color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon>{{ $t('src\\views\\documents\\list.981') }}</v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.trip`]="{ item }">
            {{ item.trip.name }}
        </template>
        <template v-slot:[`item.user`]="{ item }">
            {{ item.user.firstname }} {{ item.user.lastname }}
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
        </template>
        <template v-slot:[`item.remove`]="{ item }">
            <v-icon color="primary" @click="remove(item._id)">mdi-close-octagon-outline</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS_TITLE } from '@/vars';
    import { ACCESS } from '@/vars';

    export default {
        name: 'DocumentsList',
        components: {
        },
        data: () => ({
            ACCESS,
            BOOKING_STATUS_TITLE,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('documents', ['entities']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            await store.dispatch('documents/fetch', {});
            this.headers = [
                { text: this.$t('src\\views\\documents\\list.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\documents\\list.trip'), value: 'trip' },
                { text: this.$t('src\\views\\documents\\list.user'), value: 'user' },
            ]
            if(this.user && this.user.adminAccess && this.user.adminAccess.documents >= ACCESS.WRITE) {
                this.headers.push({ text: this.$t('src\\views\\documents\\list.edit'), align: 'right', value: 'edit' })
                this.headers.push({ text: this.$t('src\\views\\documents\\list.remove'), align: 'right', value: 'remove' })
            }
        },
        methods: {
            async create() {
                await this.$router.push({ name: 'documents-create' });
            },
            async edit(id) {
                await this.$router.push({ name: 'documents-edit', params: { id } });
            },
            async remove(id) {
                await store.dispatch('documents/delete', { id });
                await store.dispatch('documents/fetch', {});
            }
        }
    }
</script>
